import React, { Component, createContext } from "react";
import PropTypes from "prop-types";
import * as _ from "underscore";
import { getWindowDimensions } from "~utils/dom";

export const AppContext = createContext({});

class AppProvider extends Component {
  state = {
    activeSection: 0,
    cursorCenterDeltaX: 0, // 0 at center, -0.5/0.5 at edges
    cursorCenterDeltaY: 0, // 0 at center, -0.5/0.5 at edges
    cursorPositionX: 0,
    cursorPositionY: 0,
    device: ``,
    documentHeight: 0,
    scrollTop: 0,
    sectionRefs: [],
    windowWidth: 0,
    windowHeight: 0
  };

  mobileWidth = 768;

  tabletWidth = 1024;

  //
  // React lifecycle

  componentDidMount() {
    // eslint-disable-next-line no-console
    console.log(`%c Timothy Brian Lea `, `background: #213951; color: #E5D4C2`);

    if (document) {
      setTimeout(() => {
        this.updateWindowDimensions();
      });

      document.removeEventListener(
        `mousemove`,
        _.throttle(this.updateCursorPosition),
        false
      );
      document.removeEventListener(
        `resize`,
        _.throttle(this.updateWindowDimensions),
        false
      );
      document.removeEventListener(
        `scroll`,
        _.throttle(this.handleScroll),
        false
      );

      document.addEventListener(
        `mousemove`,
        _.throttle(this.updateCursorPosition),
        false
      );
      document.addEventListener(`scroll`, _.throttle(this.handleScroll), false);
    }

    if (window) {
      window.addEventListener(
        `resize`,
        _.throttle(this.updateWindowDimensions),
        false
      );
    }
  }

  componentWillUnmount() {
    if (document) {
      document.removeEventListener(
        `mousemove`,
        this.updateCursorPosition,
        false
      );
      document.removeEventListener(
        `resize`,
        this.updateWindowDimensions,
        false
      );
      document.removeEventListener(`scroll`, this.handleScroll, false);
    }
  }

  //
  // listeners

  updateCursorPosition = event => {
    this.setState(prevState => ({
      cursorCenterDeltaX: -(0.5 - event.pageX / prevState.windowWidth),
      cursorPositionX: event.pageX,
      cursorCenterDeltaY: -(
        0.5 -
        (event.pageY - window.pageYOffset) / prevState.windowHeight
      ),
      cursorPositionY: event.pageY - window.pageYOffset
    }));
  };

  handleScroll = e => {
    const { sectionRefs } = this.state;

    let activeSection;

    sectionRefs.forEach((sectionRef, index) => {
      if (!sectionRef.current) {
        return;
      }

      if (
        sectionRef.current.getBoundingClientRect().top <
        (this.state.device !== `mobile` ? 300 : 50)
      ) {
        activeSection = index;
      }
    });

    this.setState({
      activeSection,
      scrollTop: e.target.scrollingElement.scrollTop
    });
  };

  updateWindowDimensions = () => {
    let device = `desktop`;

    if (
      window.matchMedia(
        `(min-width: ${this.mobileWidth}px) and (max-width: ${this.tabletWidth}px)`
      ).matches
    ) {
      device = `tablet`;
    } else if (
      window.matchMedia(`(max-width: ${this.mobileWidth - 1}px)`).matches
    ) {
      device = `mobile`;
    }

    this.setState({
      device,
      documentHeight: document.documentElement.offsetHeight,
      windowWidth: getWindowDimensions().width,
      windowHeight: getWindowDimensions().height
    });
  };

  //
  // API

  setActiveSection = activeSection => {
    this.setState({ activeSection });
  };

  setSectionRefs = sectionRefs => {
    this.setState({ sectionRefs });
  };

  //
  // render/wrapper

  render() {
    return (
      <AppContext.Provider
        value={{
          activeSection: this.state.activeSection,
          cursorCenterDeltaX: this.state.cursorCenterDeltaX,
          cursorCenterDeltaY: this.state.cursorCenterDeltaY,
          cursorPositionX: this.state.cursorPositionX,
          cursorPositionY: this.state.cursorPositionY,
          device: this.state.device,
          documentHeight: this.state.documentHeight,
          scrollTop: this.state.scrollTop,
          sectionRefs: this.state.sectionRefs,
          windowWidth: this.state.windowWidth,
          windowHeight: this.state.windowHeight,
          //
          setActiveSection: this.setActiveSection,
          setSectionRefs: this.setSectionRefs
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

AppProvider.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired
};

export default AppProvider;
